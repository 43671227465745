import React, { useEffect, useState } from 'react';

import { TimetableRuleState } from '@chirp/access-verification';

import { useunlockAccessPointMutation } from '../../apollo';
import { getErrorMessage } from '../../helpers';
import AlertModal from '../AlertModal';
import UnlockDial from '../UnlockDial';
import animationData from '../UnlockDial/unlocking-animation-black.json';

interface TapToUnlockDialProps {
  accessPointId: string;
  accessTimeout: number;
  availability?: string;
  bottomContent?: React.ReactNode;
  hasAccess: boolean;
  isAvailable: boolean;
  onUnlockError?: (error: any) => void;
  onUnlockSuccess?: () => void;
  timetableRuleState: TimetableRuleState;
  onComplete?: () => void;
}

export const TapToUnlockDial: React.FC<TapToUnlockDialProps> = ({
  accessPointId,
  accessTimeout,
  availability,
  bottomContent,
  hasAccess,
  isAvailable,
  onUnlockError,
  onUnlockSuccess,
  timetableRuleState,
  onComplete,
}) => {
  const [isUnlocking, setIsUnlocking] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isAutomaticallyUnlocked, setIsAutomaticallyUnlocked] = useState(false);

  const [unlockAccessPoint] = useunlockAccessPointMutation({
    variables: { accessPointId },
  });

  let buttonClass = '';

  useEffect(() => {
    if (timetableRuleState === TimetableRuleState.UNLOCKED) {
      setIsAutomaticallyUnlocked(true);
      onUnlockSuccess?.();
    }
  }, [timetableRuleState, onUnlockSuccess]);

  if (disabled || !isAvailable || isAutomaticallyUnlocked || !hasAccess) {
    buttonClass = 'disabled-button';
  }

  return (
    <div className="lottie-wrapper">
      <div className="lottie-button-wrapper">
        <button
          className={`lottie-button ${buttonClass}`}
          onClick={async () => {
            try {
              await unlockAccessPoint();
              setIsUnlocking(true);
              setDisabled(true);
              onUnlockSuccess?.();
            } catch (error) {
              setErrorMessage(getErrorMessage((error as any), 'Unable to unlock entry point'));
              onUnlockError?.(error);
            }
          }}
        >
          <UnlockDial
            animationData={animationData}
            accessTimeout={accessTimeout}
            availability={availability}
            hasAccess={hasAccess}
            isAutomaticallyUnlocked={isAutomaticallyUnlocked}
            isAvailable={isAvailable}
            isRestricted={false}
            isUnlocking={isAutomaticallyUnlocked ? false : isUnlocking}
            lockedSubtext={isAutomaticallyUnlocked ? '' : 'Tap to Unlock'}
            subtextStyle={{ color: !isAvailable ? 'red' : '#252b2e' }}
            textStyle={{ color: '#252b2e' }}
            onComplete={() => {
              setIsUnlocking(false);
              setDisabled(false);
              onComplete?.();
            }}
          />
        </button>
      </div>
      {bottomContent || null}
      {errorMessage && (
        <AlertModal
          isOpen
          title="Unable to unlock entry point"
          content={errorMessage}
          closeModal={() => setErrorMessage(null)}
        />
      )}
    </div>
  );
};

export default TapToUnlockDial;
