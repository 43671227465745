import { TimetableRuleState } from '@chirp/access-verification';
import useInterval from '@use-it/interval';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  GuestPassFragment as UserRole,
  useanonymousUserRolesSubscription,
  usegetAccessPointQuery,
} from '../../apollo';
import {
  AccessRequestOptions,
  AlertModal,
  EulaModal,
  Header,
  LoadingSpinner,
  QRScanner,
  RequestSentDial,
  TapToUnlockDial,
} from '../../components';
import { Footer } from '../../components/Footer';
import SupportPhoneNumber from '../../components/SupportPhoneNumber';
import { CHECK_ACCESS_INTERVAL_MS, STRATIS_UNIVERSAL_LINK } from '../../config';
import {
  getAvailabilityDescription,
  getAvailabilityState,
  getErrorMessage,
  getImageUrl,
  isDateAllowedOnTimetable,
} from '../../helpers';
import { useAnonymousUserContext, useEulaModalState } from '../../hooks';

interface ModalState {
  isOpen: boolean;
  isCloseable: boolean;
  content: string | null;
  title: string | null;
}

interface EntryState {
  activeUserRole: UserRole | null | undefined;
  userRoles: UserRole[];
  sentRequest: boolean;
  modalState: ModalState;
}

function selectActiveUserRole(userRoles: UserRole[]) {
  return userRoles.find(({ expiresAt }) => {
    if (!expiresAt) {
      return null;
    }

    return moment(new Date(expiresAt)).isAfter(moment(new Date()));
  });
}

const Entry: React.FC = () => {
  const accessPointId = useParams().accessPointId as string;
  const navigate = useNavigate();

  // Our state is combined into one object so the values can be updated together
  const [state, setState] = useState<EntryState>({
    activeUserRole: null,
    userRoles: [],
    sentRequest: false,
    modalState: {
      isOpen: false,
      isCloseable: true,
      content: null,
      title: null,
    },
  });

  const { activeUserRole, sentRequest, modalState } = state;
  const { anonymousUserId } = useAnonymousUserContext();
  const { acceptedEulaAt, isEulaModalOpen, openEulaModal, closeEulaModal } = useEulaModalState();
  const [showQRScanner, setShowQRScanner] = useState(false);
  const [redirectRoute, setRedirectRoute] = useState('');
  const [, setShouldScrollToBottom] = useState(true);

  const { data: accessPointsData } = usegetAccessPointQuery({
    skip: !accessPointId,
    variables: { accessPointId },
    onError: (err) => {
      setState(prevState => ({
        ...prevState,
        modalState: {
          isOpen: true,
          isCloseable: false,
          title: 'Unable to access entry point',
          content: getErrorMessage(err),
        },
      }));
    },
  });

  const accessPoint = accessPointsData ? accessPointsData.accessPoint : null;
  const propertyId = accessPoint ? accessPoint.propertyId : null;

  const propertyFeatureFlags = accessPoint?.property?.featureFlags || [];
  // @TODO: Use strong typing for feature flag keys
  const isStratisProperty = propertyFeatureFlags.includes('COMMUNITY_CONNECT');
  const isVirtualDirectoryEnabled = propertyFeatureFlags.includes('VIRTUAL_DIRECTORY');

  useEffect(() => {
    if (accessPoint?.accessPointId && isStratisProperty) {
      // Redirect guest/visitor scanning smart sign with Chirp URL to the STRATIS web app
      const newQrCodeUrl = `${STRATIS_UNIVERSAL_LINK}/chirp/entry/${accessPoint.accessPointId}`;

      window.location.replace(newQrCodeUrl);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessPoint?.accessPointId, isStratisProperty]);

  useanonymousUserRolesSubscription({
    skip: !anonymousUserId || !accessPoint || isStratisProperty,
    onSubscriptionData: ({ subscriptionData }) => {
      const approvedGuestPasses = (subscriptionData.data
        ? subscriptionData.data.anonymousUserRoles
        : []).filter(g => g.scopedPropertyId === propertyId);

      if (approvedGuestPasses) {
        setState(prevState => ({
          ...prevState,
          activeUserRole: selectActiveUserRole(approvedGuestPasses),
          userRoles: approvedGuestPasses,
        }));
      }
    },
  });

  // Check every interval whether the user still has an active user role
  useInterval(() => {
    setState((prevState) => {
      const nextActiveUserRole = selectActiveUserRole(prevState.userRoles);

      const accessExpired = Boolean(prevState.activeUserRole && !nextActiveUserRole);
      const nextModalState: ModalState = accessExpired
        ? {
          isOpen: true,
          isCloseable: true,
          title: 'Access Expired',
          content: 'Your guest access has expired. Please send another request for access.',
        }
        : prevState.modalState;

      return {
        ...prevState,
        activeUserRole: nextActiveUserRole,
        // When access token expires, the "Request Sent" should not be shown
        sentRequest: nextActiveUserRole ? false : prevState.sentRequest,
        modalState: nextModalState,
      };
    });
  }, CHECK_ACCESS_INTERVAL_MS);

  const alertModal = modalState.isOpen ? (
    <AlertModal
      {...modalState}
      closeModal={() => {
        if (modalState.isCloseable) {
          setState({
            ...state,
            modalState: {
              ...modalState,
              isOpen: false,
            },
          });
        }
      }}
    />
  ) : null;

  if (!modalState.isCloseable) {
    // Users won't be able to progress with these errors. So nothing else should be rendered.
    return alertModal;
  }

  if (accessPoint && !isStratisProperty) {
    const { name, image, property, publicAccessPointRoles } = accessPoint;
    const { timezone } = property;
    const { GUEST_PROPERTY_KEY: guestRole, VENDOR_PASS: vendorPassRole } = publicAccessPointRoles;
    const { schedule } = guestRole;

    const accessTimeout = accessPoint.accessTimeout || 5000;
    const { name: propertyName, supportPhoneNumber } = property;
    const cloudId = image ? image.cloudId : null;

    const accessTime = new Date();
    let isAccessPointAvailable = true;

    if (!isDateAllowedOnTimetable(accessTime, schedule, timezone)) {
      isAccessPointAvailable = false;
    }

    const availability = getAvailabilityDescription(accessTime, schedule, timezone);
    const imageUrl = getImageUrl(cloudId, { width: 750, crop: 'scale' });
    const availabilityState = getAvailabilityState(accessTime, schedule, timezone);
    let message = '';

    if (!guestRole.hasAccess && vendorPassRole.hasAccess) {
      message = 'This entry is for vendors only. Enter your vendor passcode by selecting the option below.';
    } else if (isVirtualDirectoryEnabled) {
      message = 'Guests can request access from a resident by choosing one of the options below.';
    } else {
      message = 'Guests can request access from a resident by tapping the button below.';
    }

    const welcomeMessage = (
      <div className="content">
        <h2 className="h2 text-dark">Welcome to {propertyName}!</h2>
        <div className="p text-mid-gray">
          {availabilityState === TimetableRuleState.CONTROLLED && isAccessPointAvailable && message}
          {!isVirtualDirectoryEnabled &&
            <SupportPhoneNumber supportPhoneNumber={supportPhoneNumber} />
          }
        </div>
      </div>
    );

    const renderEntryWithAccess = () => {
      if (activeUserRole?.expiresAt) {
        return (
          <>
            <TapToUnlockDial
              accessPointId={accessPointId}
              accessTimeout={accessTimeout}
              isAvailable={isAccessPointAvailable}
              hasAccess={!!activeUserRole}
              timetableRuleState={availabilityState}
              availability={availability}
              bottomContent={
                <div className="content-bottom">
                  <img src="/images/icon-alert.png" alt="" className="icon-bottom" />
                  <div className="p text-dark text-bold">
                    Request approved! You can access entry points until{' '}
                    {moment(activeUserRole.expiresAt).format('h:mm A')}.
                  </div>
                </div>
              }
              onComplete={() => {
                setShouldScrollToBottom((prevState) => {
                  if (prevState) {
                    window.scrollTo({ behavior: 'smooth', top: document.body.scrollHeight });
                    return false;
                  }

                  return prevState;
                });
              }}
            />
            <button
              className="blue-button justify-content-center"
              onClick={() => setShowQRScanner(true)}
            >
              <h1 className="h2">Scan Another QR Code</h1>
            </button>
            {showQRScanner &&
              <QRScanner
                expiresAt={activeUserRole.expiresAt}
                onClickBack={() => setShowQRScanner(false)}
                headerSubtext={moment(activeUserRole.expiresAt).format('MMM D, h:mm A')}
                headerText="Access Expires:"
                roleKey="GUEST_PROPERTY_KEY"
              />
            }
          </>
        );
      }
    };

    const renderEntryWithoutAccess = () => {
      const residentLink = (
        <div className="content" style={{ paddingBottom: '30px', alignItems: 'center' }}>
          <Link to="/resident" style={{ textDecoration: 'underline' }}>
            <span>I&#x27;m a Resident</span>
          </Link>
        </div>
      );

      if (!isAccessPointAvailable) {
        return (
          <>
            {welcomeMessage}
            <button
              disabled
              className="blue-button w-inline-block"
            >
              <h1 className="h2" style={{ color: 'red' }}>Entrance is Closed {availability && `(${availability})`}</h1>
            </button>
            {residentLink}
          </>
        );
      }

      if (availabilityState === TimetableRuleState.UNLOCKED) {
        return (
          <>
            {welcomeMessage}
            <button
              disabled
              className="blue-button w-inline-block"
            >
              <h1 className="h2" style={{ color: '#252b2e' }}>Entrance is Unlocked</h1>
            </button>
            {residentLink}
          </>
        );
      }

      if (!sentRequest && anonymousUserId) {
        return (
          <>
            {welcomeMessage}
            {guestRole.hasAccess && (
              <AccessRequestOptions
                accessPointId={accessPointId}
                accessPointName={name}
                anonymousUserId={anonymousUserId}
                onShareSuccess={() => setState({
                  ...state,
                  sentRequest: true,
                })}
                propertyName={propertyName}
                supportPhoneNumber={supportPhoneNumber}
                isVirtualDirectoryEnabled={isVirtualDirectoryEnabled}
              />
            )}
            {guestRole.hasAccess && isVirtualDirectoryEnabled && isAccessPointAvailable &&
              <>
                <div style={{ marginBottom: '15px' }}></div>
                <Link
                  onClick={(e) => {
                    if (!acceptedEulaAt) {
                      e.preventDefault();
                      openEulaModal();
                      setRedirectRoute(`/property-directory/${accessPoint.accessPointId}`);
                    }
                  }}
                  to={`/property-directory/${accessPoint.accessPointId}`}
                  className="blue-button w-inline-block"
                >
                  <span
                    className="display-flex-wrapper"
                    style={{ alignItems: 'flex-start', textAlign: 'left' }}
                  >
                    <h1 className="h2">Property Directory</h1>
                    <span className="p text-white">
                      Request access by selecting a contact from the directory
                    </span>
                  </span>
                  <img src="/images/search-white.png" alt="" className="icon-20px" />
                </Link>
              </>
            }
            {vendorPassRole.hasAccess && isAccessPointAvailable &&
              <>
                <div style={{ marginBottom: '15px' }}></div>
                <Link
                  onClick={(e) => {
                    if (!acceptedEulaAt) {
                      e.preventDefault();
                      openEulaModal();
                      setRedirectRoute(`/vendor/${accessPoint.accessPointId}`);
                    }
                  }}
                  to={`/vendor/${accessPoint.accessPointId}`}
                  className="blue-button w-inline-block"
                >
                  <span
                    className="display-flex-wrapper"
                    style={{ alignItems: 'flex-start', textAlign: 'left' }}
                  >
                    <h1 className="h2">Vendor Passcode</h1>
                    <span className="p text-white">
                      Enter your Vendor Passcode for access
                    </span>
                  </span>
                  <span className="p text-white" style={{ fontSize: 30 }}>#</span>
                </Link>
              </>
            }
            {residentLink}
          </>
        );
      }

      if (sentRequest) {
        return <RequestSentDial />;
      }
    };

    return (
      <>
        <div>
          <Header title={name} subtext={propertyName} />
          {imageUrl && (
            <div className="entry-point-image">
              <img
                src={imageUrl}
                sizes="(max-width: 750px) 100vw, 750px"
                alt={propertyName}
              />
            </div>
          )}
          {!activeUserRole && renderEntryWithoutAccess()}
          {activeUserRole && renderEntryWithAccess()}
          <EulaModal
            isOpen={isEulaModalOpen}
            closeModal={closeEulaModal}
            onAcceptEula={() => {
              navigate(redirectRoute);
            }}
          />
          {alertModal}
        </div>
        <Footer />
      </>
    );
  }

  return <LoadingSpinner />;
};

export default Entry;
