import React from 'react';
import { Image } from '../../apollo';
import { Header } from '../../components';
import { getImageUrl } from '../../helpers';

interface Props {
  title: string;
  subTitle: string;
  image?: Image | null;
}

const VendorAccessHeader: React.FC<Props> = ({ title, subTitle, image }) => {
  const cloudId = image ? image?.cloudId : null;
  const imageUrl =  getImageUrl(cloudId, { width: 750, crop: 'scale' });

  return (
    <>
      <Header title={title} subtext={subTitle} />
      {imageUrl && (
          <div className="entry-point-image">
            <img
              src={imageUrl}
              sizes="(max-width: 750px) 100vw, 750px"
              alt={title}
            />
          </div>
        )}
    </>
  );
};

export default VendorAccessHeader;
