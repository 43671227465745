import 'react-toastify/dist/ReactToastify.css';

import { ApolloError } from '@apollo/client';
import {
  AccessPointWithLocation,
  EngrainMap,
  EngrainMapViewMode,
  GeoLocationPermissionStatus,
} from '@chirp/react-engrain-map';
import { useLocalStorage } from '@rehooks/local-storage';
import useInterval from '@use-it/interval';
import delay from 'delay';
import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import {
  GuestPassFragment as GuestPass,
  useclaimGuestPassMutation,
  usegetGuestPassQuery,
} from '../../apollo';
import MapIcon from '../../assets/map-icon.svg';
import QrCodeIcon from '../../assets/qr-code-icon.svg';
import {
  AlertModal,
  EulaModal,
  Header,
  LoadingSpinner,
  QRScanner,
  TapToUnlockDrawer,
} from '../../components';
import { Footer } from '../../components/Footer';
import {
  ACCESS_REQUEST_EXPIRATION_MINUTES,
  CHECK_ACCESS_INTERVAL_MS,
  ENGRAIN_API_KEY,
} from '../../config';
import { getErrorMessage, getImageUrl } from '../../helpers';
import { useAlertModalState, useAnonymousUserContext, useEulaModalState } from '../../hooks';

interface GuestState {
  submitting: boolean;
  showQRScanner: boolean;
  guestPass: GuestPass | null | undefined;
  isPending: boolean;
  isExpired: boolean;
}

function getGuestPassState(guestPass?: GuestPass | null) {
  if (!guestPass) {
    return { isPending: false, isExpired: false };
  }

  const { activatesAt, expiresAt } = guestPass;

  return {
    isPending: !!activatesAt && moment(new Date()).isBefore(activatesAt),
    isExpired: !!expiresAt && moment(new Date()).isSameOrAfter(expiresAt),
  };
}

const Guest: React.FC = () => {
  const userRoleId = useParams().userRoleId as string;
  const navigate = useNavigate();
  const { alertModalState, setAlertModalState } = useAlertModalState();

  const [state, setState] = useState<GuestState>({
    submitting: false,
    showQRScanner: false,
    guestPass: null,
    isPending: false,
    isExpired: false,
  });

  const { acceptedEulaAt, isEulaModalOpen, openEulaModal, closeEulaModal } = useEulaModalState();
  const [finishedMapTutorial, setFinishedMapTutorial] = useLocalStorage<string>('finishedMapTutorial');
  const [
    geoLocationPermissionStatus,
    setGeoLocationPermissionStatus,
  ] = useState<GeoLocationPermissionStatus>(GeoLocationPermissionStatus.UNKNOWN);

  const { anonymousUserId } = useAnonymousUserContext();
  const { submitting, showQRScanner, guestPass } = state;
  const [viewPropertyMap, setViewPropertyMap] = useState(false);
  const [mapLocation, setMapLocation] = useState<AccessPointWithLocation | null>(null);
  const [showUnlockDrawer, setShowUnlockDrawer] = useState(false);
  const [accessPointId, setAccessPointId] = useState<string | null>(null);
  const [showTooltip, setShowTooltip] = useState(true);

  // Check every interval whether the guest pass is within time frame of access
  useInterval(() => {
    setState(() => {
      return {
        ...state,
        ...getGuestPassState(state.guestPass),
      };
    });
  }, CHECK_ACCESS_INTERVAL_MS);

  const showNetworkErrorModal = () => {
    setAlertModalState({
      isOpen: true,
      title: 'No internet connection',
      content: 'Please check your internet connection and try again',
    });
  };

  usegetGuestPassQuery({
    skip: !userRoleId,
    fetchPolicy: 'network-only',
    variables: { userRoleId: userRoleId as string },
    onCompleted: (guestPassData) => {
      const loadedGuestPass = guestPassData ? guestPassData.guestPass : null;

      setState({
        ...state,
        guestPass: loadedGuestPass,
        ...getGuestPassState(loadedGuestPass),
      });
    },
    onError: (err) => {
      if (err instanceof ApolloError && err.networkError) {
        showNetworkErrorModal();
      } else {
        setAlertModalState({
          isOpen: true,
          title: 'Guest pass unavailable',
          content: getErrorMessage(err, 'We were unable to load your guest pass.'),
          queryError: true,
        });
      }
    },
  });

  const [claimGuestPass] = useclaimGuestPassMutation({
    variables: { userRoleId: userRoleId as string },
  });

  if (!userRoleId || state.isExpired) {
    return (
      <AlertModal
        title="Guest pass unavailable"
        content={state.isExpired
          ? 'This guest pass has expired'
          : 'We were unable to load your guest pass.'
        }
        canClose={false}
        isOpen
      />
    );
  }

  if (alertModalState.queryError) {
    return <AlertModal {...alertModalState} canClose={false} />;
  }

  const onClickActivateHandler = async () => {
    try {
      setState({ ...state, submitting: true });

      const { data: claimGuestPassData } = await claimGuestPass();
      const claimedGuestPass = claimGuestPassData?.claimedGuestPass;

      setState({
        showQRScanner,
        guestPass: claimedGuestPass,
        submitting: false,
        ...getGuestPassState(claimedGuestPass),
      });
    } catch (error) {
      if (error instanceof ApolloError && error.networkError) {
        showNetworkErrorModal();
      } else {
        setAlertModalState({
          isOpen: true,
          title: 'Unable to claim guest pass',
          content: getErrorMessage(error as any, 'We were unable to claim your guest pass.'),
        });
      }

      setState({ ...state, submitting: false });
    }
  };

  const onClickToggleQRHandler = () => {
    setState({ ...state, showQRScanner: !showQRScanner });
  };

  if (guestPass && guestPass.scopedProperty) {
    const { scopedProperty, assignedToAnonymousUserId, activatesAt, expiresAt } = guestPass;
    const { address, image, name: propertyName } = scopedProperty;

    const { propertyMap } = guestPass.scopedProperty;

    const cloudId = image ? image.cloudId : null;
    const imageUrl = getImageUrl(cloudId, { width: 750, crop: 'scale' });

    const isClaimed = anonymousUserId && anonymousUserId === assignedToAnonymousUserId;
    const canBeClaimed = !state.isPending && !isClaimed;

    const activationTime = activatesAt ? moment(activatesAt).format('MMM D, h:mma') : null;
    const expirationTime = expiresAt ? moment(expiresAt).format('MMM D, h:mma') : null;

    let buttonText = '';

    if (!isClaimed) {
      buttonText = submitting ? 'Activating Guest Pass...' : 'Activate Now';
    } else {
      buttonText = `${showQRScanner ? 'Close' : 'Open'} QR Code Scanner`;
    }

    // Fix "Close QR Code Scanner" button to bottom of the screen
    const wrapperStyle = (showQRScanner && expiresAt)
      ? { height: '-webkit-fill-available' }
      : undefined;

    const googleMapsUrl = `https://maps.google.com/?q=${propertyName},${address}`;

    return <>
      <div className="guest" style={wrapperStyle}>
        {!viewPropertyMap &&
          <>
            <Header title={propertyName} subtext="Guest Pass" />
            <div className="guest-content-wrapper">
              {imageUrl && (
                <img
                  src={imageUrl}
                  sizes="(max-width: 750px) 100vw, 750px"
                  alt={propertyName}
                />
              )}
              <div className="content guest-content">
                <h2 className="h2-dark">
                  {!isClaimed ? `Welcome to ${propertyName}!` : 'Guest Pass Activated!'}
                </h2>
                {!canBeClaimed && state.isPending && (
                  <div className="p">
                    {`Guest pass cannot be activated until ${activationTime}.`}
                  </div>
                )}
                {canBeClaimed && (
                  <div className="p">
                    {activationTime && expirationTime
                      ? `This guest pass is valid until ${expirationTime}.`
                      : `Guest passes can be used for ${ACCESS_REQUEST_EXPIRATION_MINUTES} minutes after activation.`}
                  </div>
                )}
                {isClaimed && expirationTime && (
                  <>
                    <div className="p">
                      {`You have access to ${propertyName} until ${expirationTime}.`}
                    </div>
                    <br />
                    <div className="p">
                      {'Open the QR Code Scanner when you are near Chirp signage to unlock entry points.'}
                    </div>
                    {propertyMap &&
                      <>
                        <div className="section1">
                          <button
                            onClick={() => onClickToggleQRHandler()}
                            className="sectionAdditionalButton"
                          >
                            <img alt="" src={QrCodeIcon} /> Scan the QR Code
                          </button>
                        </div>
                        <div className="section2">
                          <span className="sectionTitle">Map out your tour</span>
                          <span className="sectionBody">
                            View a map of the property to help make your self guided tour a
                            success!
                          </span>
                          <button
                            onClick={() => setViewPropertyMap(true)}
                            className="sectionAdditionalButton"
                          >
                            <img alt="" src={MapIcon} /> View Property Map
                          </button>
                        </div>
                      </>
                    }
                  </>
                )}
                <div className="sectionBottom">
                  <span className="sectionTitle">Get Driving Directions</span>
                  <a
                    href={googleMapsUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="LinkClassHome"
                  >
                    {address}
                  </a>
                </div>
              </div>
            </div>
          </>
        }
        {(showQRScanner && expiresAt) &&
          <QRScanner
            isPropertyMapVisible={viewPropertyMap}
            expiresAt={expiresAt}
            onClickBack={() => onClickToggleQRHandler()}
            headerSubtext={moment(expiresAt).format('MMM D, h:mm A')}
            headerText="Access Expires:"
            onUnlockError={(error) => {
              // @TODO: Check for FORBIDDEN error code once it's provided
              if (getErrorMessage(error) === 'You do not have access to this entry point') {
                // Access has likely expired if the user receives this error message.
                // Let's redirect them to the callbox killer page to request new access.
                navigate(`/qr/${accessPointId}`);
              }
            }}
            onFinishScanning={async (nextAccessPointId) => {
              setAccessPointId(nextAccessPointId);
              setShowUnlockDrawer(true);
              onClickToggleQRHandler();
            }}
            roleKey="GUEST_PROPERTY_KEY"
          />
        }
        {showUnlockDrawer && accessPointId && expiresAt &&
          <div className="drawer-overlay"
            onClick={(e) => {
              if ((e.target as any).className === 'drawer-overlay') {
                setShowUnlockDrawer(false);
              }
            }}
          >
            <TapToUnlockDrawer
              accessPointId={accessPointId}
              expiresAt={expiresAt}
              onFetchedAccessPoint={async (accessPoint) => {
                const nextMapLocation = accessPoint.mapLocation;
                if (nextMapLocation) {
                  setMapLocation(nextMapLocation);
                  toast.success('Location is updated!');
                  await delay(500);
                  setMapLocation(null);
                }
              }}
              onClose={() => setShowUnlockDrawer(false)}
              roleKey="GUEST_PROPERTY_KEY"
            />
          </div>
        }
        {(!isClaimed || (isClaimed && !propertyMap)) &&
          <button
            disabled={(!canBeClaimed && state.isPending) || submitting}
            className="blue-button justify-content-center"
            onClick={() => {
              if (canBeClaimed) {
                !acceptedEulaAt ? openEulaModal() : onClickActivateHandler();
              } else {
                onClickToggleQRHandler();
              }
            }}
          >
            <h1 className="h2">
              {buttonText}
            </h1>
          </button>
        }
      </div>
      {!!propertyMap && viewPropertyMap &&
        <div className="map-container">
          <div id="engrain-map">
            <EngrainMap
              propertyName={propertyName}
              viewMode={EngrainMapViewMode.VIEW}
              mapId={propertyMap.externalMapId}
              apiKey={ENGRAIN_API_KEY}
              floorsData={propertyMap.externalFloors}
              unitsData={propertyMap.externalUnits}
              bookedUnits={guestPass.appointmentInfo.bookedUnits}
              unitColor="#687987"
              selectedUnitColor="#0076CC"
              promptTutorial={finishedMapTutorial !== userRoleId}
              onFinishedTutorial={() => {
                setFinishedMapTutorial(userRoleId);
              }}
              onApprovedLocationStatus={status => setGeoLocationPermissionStatus(status)}
              geoLocationPermissionStatus={geoLocationPermissionStatus}
              toggleBackButton={() => setViewPropertyMap(false)}
              toggleQrScanner={() => {
                onClickToggleQRHandler();
                if (showTooltip) {
                  setShowTooltip(false);
                }
              }}
              zoomToLocation={mapLocation}
              accessPoints={[]}
              onError={((error: any) => toast.error(error))}
            />
          </div>
        </div>
      }
      <AlertModal
        {...alertModalState}
        closeModal={() => {
          setAlertModalState({
            ...alertModalState,
            isOpen: false,
          });
        }}
      />
      <EulaModal
        isOpen={isEulaModalOpen}
        closeModal={closeEulaModal}
        onAcceptEula={async () => {
          await onClickActivateHandler();
        }}
      />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      <Footer />
    </>;
  }

  return <LoadingSpinner />;
};

export default Guest;
