import { useLocalStorage } from '@rehooks/local-storage';
import jwtDecode from 'jwt-decode';

interface AnonymousTokenPayload {
  anonymousUserId?: string;
  smartSignProximity?: {
    // @TODO: Set specific propertyId or accessPointId to be verified
    exp: number;
  };
}

const getDefaultAnonymousUserContext = () => {
  return {
    anonymousUserId: null,
    isSmartSignProximityExpired: () => true,
  };
};

const isTokenClaimExpired = (exp: number | undefined): boolean => {
  if (!exp || typeof exp !== 'number') {
    return true;
  }

  const expMs = exp * 1000; // Convert seconds to milliseconds

  return Date.now() >= expMs;
};

export function useAnonymousUserContext() {
  const [accessToken] = useLocalStorage<string>('chirpAccessToken');

  if (typeof accessToken !== 'string') {
    return getDefaultAnonymousUserContext();
  }

  try {
    const payload = jwtDecode<AnonymousTokenPayload>(accessToken);

    return {
      anonymousUserId: payload.anonymousUserId,
      smartSignProximity: payload.smartSignProximity,
      isSmartSignProximityExpired: () => isTokenClaimExpired(payload.smartSignProximity?.exp),
    };
  } catch {
    return getDefaultAnonymousUserContext();
  }
}
