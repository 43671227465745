import { useDebounceCallback } from '@react-hook/debounce';
import useInterval from '@use-it/interval';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { usegetVirtualDirectoryQuery } from '../../apollo';
import {
  Header,
  LoadingSpinner,
  VirtualDirectoryContactList,
  VirtualDirectoryContactRow,
  VirtualDirectoryInfoModal,
} from '../../components';
import { Footer } from '../../components/Footer';
import { CHECK_ACCESS_INTERVAL_MS } from '../../config';
import { useAnonymousUserContext } from '../../hooks';
import { getIsLeasingOfficeClosed } from './getIsLeasingOfficeClosed';

const VirtualDirectory: React.FC = () => {
  const accessPointId = useParams().accessPointId as string;
  const navigate = useNavigate();
  const { isSmartSignProximityExpired } = useAnonymousUserContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [proximityExpired, setProximityExpired] = useState(isSmartSignProximityExpired());
  const [isLeasingOfficeClosed, setIsLeasingOfficeClosed] = useState<boolean>(false);
  const [search, setSearch] = useState<string | null>(null);
  const debouncedSetSearch = useDebounceCallback(setSearch, 500);
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  const { data, previousData, loading, error: queryError } = usegetVirtualDirectoryQuery({
    skip: !accessPointId || proximityExpired,
    variables: {
      input: { accessPointId, search: search || null },
    },
    onCompleted: ({ virtualDirectoryPublic }) => {
      setIsLeasingOfficeClosed(getIsLeasingOfficeClosed(virtualDirectoryPublic.property));
    },
  });

  const property = data?.virtualDirectoryPublic.property || null;
  const virtualDirectoryUsers = data?.virtualDirectoryPublic.listedUsers || [];

  useInterval(() => {
    setProximityExpired(isSmartSignProximityExpired());
    setIsLeasingOfficeClosed(getIsLeasingOfficeClosed(property));
  }, CHECK_ACCESS_INTERVAL_MS);

  // This data will not change
  const unitsDisplayed = (data || previousData)?.virtualDirectoryPublic.unitsDisplayed;

  let searchPlaceholder = queryError ? 'Search' : '...'; // Default error and loading state

  if (unitsDisplayed === true) {
    searchPlaceholder = 'Search by resident name or unit number';
  } else if (unitsDisplayed === false) {
    searchPlaceholder = 'Search by resident name';
  }

  const error = proximityExpired
    ? new Error('Please re-scan the QR code to continue using the property directory')
    : queryError;

  return (
    <>
      <div>
        <Header
          title="Property Directory"
          leftElement={
            <button className="button-with-icon" onClick={() => navigate(-1)}>
              <img src="/images/arrow-left.png" alt="" className="arrow-icon" />
            </button>
          }
          rightElement={
            <button className="button-with-icon" onClick={() => setInfoModalOpen(true)}>
              <img src="/images/question.svg" alt="" className="info-icon" />
            </button>
          }
          style={{ display: 'flex', flex: 1 }}
        />
        <div className="contacts-lookup-wrapper">
          <div className="display-flex-wrapper">
            <input
              className="search-bar"
              type="text"
              placeholder={searchPlaceholder}
              onChange={e => debouncedSetSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="contacts-wrapper">
          {loading && <LoadingSpinner />}
          {!loading && (
            <>
              {!search && property?.virtualDirectoryPhoneNumber && (
                <VirtualDirectoryContactRow
                  key="leasing-office"
                  accessPointId={accessPointId}
                  contactName="Leasing Office"
                  disabled={isLeasingOfficeClosed}
                  actionContent={isLeasingOfficeClosed
                    ? <span style={{ color: '#333' }}>CLOSED</span>
                    : undefined
                  }
                />
              )}
              <VirtualDirectoryContactList
                accessPointId={accessPointId}
                contacts={virtualDirectoryUsers}
                error={error}
              />
            </>
          )}
        </div>
        <VirtualDirectoryInfoModal
          closeModal={() => setInfoModalOpen(false)}
          isOpen={infoModalOpen}
          supportPhoneNumber={property?.supportPhoneNumber}
        />
      </div>
      <Footer />
    </>
  );
};

export default VirtualDirectory;
