// tslint:disable:max-line-length
import React from 'react';
import Modal from 'react-modal';

interface VendorPasscodeInfoModalProps {
  closeModal: () => void;
  isOpen: boolean;
  isVirtualDirectoryEnabled: boolean;
  supportPhoneNumber?: string | null;
}

Modal.setAppElement('#root');

export const VendorPasscodeInfoModal = (props: VendorPasscodeInfoModalProps) => {
  const { closeModal, isOpen } = props;

  return (
    <Modal
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={closeModal}
      isOpen={isOpen}
    >
      <div className="modal">
        <div className="modal-text">
          <h1 className="h1 text-white">What is a Vendor Passcode?</h1>
          <span className="spacer"></span>
          <div className="p text-white">
            <p>A unique vendor passcode is granted by property staff to frequent vendors.</p>
            {props.isVirtualDirectoryEnabled &&
              <p>If you do not have a Vendor Passcode, please use the Property Directory to call the Leasing Office.</p>
            }
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VendorPasscodeInfoModal;
