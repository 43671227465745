import { ApolloError } from '@apollo/client';
import useInterval from '@use-it/interval';
import delay from 'delay';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usecheckVendorPassClaimMutation, usegetAccessPointQuery } from '../../apollo';
import {
  AlertModal,
  LoadingSpinner,
  QRScanner,
  TapToUnlockDial,
  VendorPasscodeVerification,
} from '../../components';
import { Footer } from '../../components/Footer';
import { Timetable } from '../../components/Timetable';
import { CHECK_ACCESS_INTERVAL_MS } from '../../config';
import {
  getAvailabilityDescription,
  getAvailabilityState,
  getErrorMessage,
  isDateAllowedOnTimetable,
} from '../../helpers';
import VendorAccessHeader from './VendorAccessHeader';

interface ModalState {
  isOpen: boolean;
  isCloseable: boolean;
  content: JSX.Element | string | null;
  title: string | null;
}

interface VendorAccessState {
  showQRScanner: boolean;
  accessExpired: boolean;
  modalState: ModalState;
}

const VendorAccess: React.FC = () => {
  const accessPointId = useParams().accessPointId as string;
  const [hasAttemptedUnlock, setHasAttemptedUnlock] = useState(false);

  const [state, setState] = useState<VendorAccessState>({
    showQRScanner: false,
    accessExpired: false,
    modalState: {
      isOpen: false,
      isCloseable: true,
      content: null,
      title: null,
    },
  });

  const { accessExpired, modalState } = state;

  const onError = (error: any, title = 'Unexpected Error', isCloseable = false) => {
    setState({
      ...state,
      modalState: {
        title,
        isCloseable,
        isOpen: true,
        content: getErrorMessage(error as ApolloError),
      },
    });
  };

  const { data: accessPointsData, loading: loadingAccessPoint } = usegetAccessPointQuery({
    skip: !accessPointId,
    variables: { accessPointId },
    onError: error => onError(error, 'Unable to access entry point'),
  });

  const accessPoint = accessPointsData?.accessPoint;
  const property = accessPoint?.property;
  const propertyFeatureFlags = property?.featureFlags || [];
  const isVirtualDirectoryEnabled = propertyFeatureFlags.includes('VIRTUAL_DIRECTORY');

  const [checkVendorPassClaim, vendorPassClaimResult] = usecheckVendorPassClaimMutation({
    variables: {
      input: { accessPointId },
    },
  });

  useEffect(() => {
    if (property?.propertyId) {
      checkVendorPassClaim().catch(error => onError(error, 'Unable to verify vendor access'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property?.propertyId]);

  const { data: vendorPassClaimData, loading: loadingVendorPassClaim } = vendorPassClaimResult;

  const vendorUserRole = vendorPassClaimData?.vendorPassUserRole;
  const vendorSchedule = vendorUserRole?.schedule;
  const vendorPassActivatesAtFormatted = vendorUserRole?.activatesAt
    ? moment(vendorUserRole?.activatesAt).format('MMM D, h:mma')
    : null;
  const vendorPassExpiresAtFormatted = vendorUserRole?.expiresAt
    ? moment(vendorUserRole?.expiresAt).format('MMM D, h:mma')
    : null;

  useInterval(() => {
    setState({
      ...state,
      accessExpired: vendorUserRole?.expiresAt ?
        moment(new Date()).isSameOrAfter(vendorUserRole.expiresAt) : false,
    });
  }, CHECK_ACCESS_INTERVAL_MS);

  const alertModal = modalState.isOpen ? (
    <AlertModal
      {...modalState}
      closeModal={() => {
        if (modalState.isCloseable) {
          setState({
            ...state,
            modalState: {
              ...modalState,
              isOpen: false,
            },
          });
        }
      }}
    />
  ) : null;

  if (!modalState.isCloseable) {
    return alertModal;
  }

  if (loadingAccessPoint || loadingVendorPassClaim || !accessPoint) {
    return <LoadingSpinner />;
  }

  if (!vendorUserRole || accessExpired) {
    return (
      <>
        <VendorPasscodeVerification
          isVirtualDirectoryEnabled={isVirtualDirectoryEnabled}
          onVerified={() => checkVendorPassClaim().catch(onError)}
        />
        <Footer />
      </>
    );
  }

  const onUnlockError = (error: any) => {
    // @TODO: Check for FORBIDDEN error code once it's provided
    if (getErrorMessage(error) === 'You do not have access to this entry point') {
      // Force vendor to re-verify passcode if it was regenerated
      checkVendorPassClaim().catch(onError);
    } else if (!hasAttemptedUnlock) {
      delay(8000).then(() => setHasAttemptedUnlock(true))
    } else {
      onError(error, 'Unexpected Error', true);
    }
  };

  if (property && vendorUserRole && !accessExpired) {
    const propertyName = property?.name;
    const { schedule: accessPointSchedule } = accessPoint.publicAccessPointRoles.VENDOR_PASS;
    const { timezone } = property;

    const accessTime = new Date();
    const apScheduleAllowed = isDateAllowedOnTimetable(accessTime, accessPointSchedule, timezone);
    const vendorScheduleAllowed = isDateAllowedOnTimetable(accessTime, vendorSchedule, timezone);
    const apAvailability = getAvailabilityDescription(accessTime, accessPointSchedule, timezone);
    const apAvailableState = getAvailabilityState(accessTime, accessPointSchedule, timezone);

    if (!hasAttemptedUnlock) {
      return (
        <>
          <div className="guest-content-wrapper">
            <VendorAccessHeader
              title={accessPoint.name}
              subTitle={propertyName}
              image={accessPoint.image}
            />
            <TapToUnlockDial
              accessPointId={accessPointId}
              accessTimeout={accessPoint.accessTimeout}
              isAvailable={apScheduleAllowed}
              hasAccess={vendorScheduleAllowed}
              timetableRuleState={apAvailableState}
              availability={apAvailability}
              bottomContent={
                <div className="content-bottom" style={{ alignItems: 'flex-start' }}>
                  <img src="/images/icon-alert.png" alt="" className="icon-bottom" />
                  <div className="p text-dark text-bold">
                    <div className="p text-dark text-bold">Access granted!</div>
                    <div className="spacer"></div>
                      {(vendorSchedule || vendorPassExpiresAtFormatted) &&
                        <div>Your Access Schedule:</div>
                      }
                      {vendorPassExpiresAtFormatted && !vendorSchedule &&
                        <div>{vendorPassActivatesAtFormatted} - {vendorPassExpiresAtFormatted}</div>
                      }
                      {vendorSchedule && <Timetable timetable={vendorSchedule} />}
                  </div>
                </div>
              }
              onUnlockError={onUnlockError}
              onUnlockSuccess={() => {
                delay(accessPoint.accessTimeout + 8000)
                  .then(() => setHasAttemptedUnlock(true))
                  .catch(onError);
              }}
            />
            {alertModal}
          </div>
          <Footer />
        </>
      );
    }

    return (
      <>
        <div className="guest-content-wrapper">
          <VendorAccessHeader
            title={propertyName}
            subTitle="Vendor Access"
            image={property.image}
          />
          <div className="content guest-content">
            <h2 className="h1 text-dark">Access Granted!</h2>
            <div className="spacer"></div>
            <div className="p">You have access to {propertyName}.</div>
            <div className="spacer"></div>
            <div className="p">
              See a Chirp QR code? Open the QR Scanner below to unlock the entry point.
            </div>
            {(vendorSchedule || vendorPassExpiresAtFormatted) &&
              <>
                <div className="spacer"></div>
                <div className="p">Your Access Schedule:</div>
              </>
            }
            {vendorPassExpiresAtFormatted && !vendorSchedule &&
              <div className="p">
                {vendorPassActivatesAtFormatted} - {vendorPassExpiresAtFormatted}
              </div>
            }
            {vendorSchedule &&
              <div className="p"><Timetable timetable={vendorSchedule} /></div>
            }
          </div>
          <div className="spacer"></div>
          {(state.showQRScanner) &&
            <QRScanner
              expiresAt={vendorUserRole.expiresAt}
              onClickBack={() => setState({ ...state, showQRScanner: false })}
              onUnlockError={onUnlockError}
              headerSubtext={vendorPassExpiresAtFormatted}
              headerText={vendorPassExpiresAtFormatted && 'Access Expires:'}
              onFinishScanning={() => setState({ ...state, showQRScanner: false })}
              roleKey="VENDOR_PASS"
            />
          }
          <button
            className="blue-button justify-content-center"
            onClick={() => setState({ ...state, showQRScanner: true })}
          >
            <h1 className="h2">Open QR Code Scanner</h1>
          </button>
          {alertModal}
        </div>
        <Footer />
      </>
    );
  }

  return <LoadingSpinner />;
};

export default VendorAccess;
